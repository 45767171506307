import React from 'react'
import PropTypes from 'prop-types'

import useStaticModule from '@hooks/useStaticModule'
import Container from '@objects/container'
import Copy from '@objects/copy'

function Impressum({ data, pageContext }) {
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)

  const content = getStaticFieldValue(
    'accessiblity.text',
    'accessiblity.text.copy'
  )

  return (
    <Container role="region" aria-label="Impressum">
      <Copy className="lg:w-10/12 lg:mx-auto" html={content} div />
    </Container>
  )
}

Impressum.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Impressum
